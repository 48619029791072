@font-face {
	font-family: 'Roboto Thin';
	src: local('Roboto Thin'), local('Roboto-Thin'), url('/fonts/roboto/Roboto-Thin.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto Light';
	src: local('Roboto Light'), local('Roboto-Light'), url('/fonts/roboto/Roboto-Light.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto Regular';
	src: local('Roboto Regular'), local('Roboto-Regular'), url('/fonts/roboto/Roboto-Regular.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto Medium';
	src: local('Roboto Medium'), local('Roboto-Medium'), url('/fonts/roboto/Roboto-Medium.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

.text-green {
	color: $green;
}

.text-light-green {
	color: $light-green;
}

.text-dark-gray {
	color: $dark-gray;
}

.text-small {
	font-size: .75rem;
}

.text-one-stroke {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	/*! autoprefixer: off */
	-webkit-box-orient: vertical;
	/*! autoprefixer: on */
	overflow: hidden;
	text-overflow: ellipsis;
}
