.catalog {
    @include display-flex();
    @include border-radius(.5rem);
    flex-direction: row;
    background-color: $white;
    flex-grow: 1;
    border: 1px solid $gray;

    &__filter {
        flex-basis: 17.1rem;
        margin-right: 2rem;
        padding: 2rem 1.25rem 1rem 2rem;
        flex-shrink: 0;

        @include below($lg) {
            display: none;
        }

        &__block {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid rgba(144, 144, 144, .25);

            &:last-child {
                border-bottom: none;
            }

            &-title {
                display: inline-block;
                font-family: $roboto-regular;
                margin-bottom: .5rem;
            }

            &-checkbox {
                color: $black;
                text-decoration: none;
                margin-bottom: .4rem;
            }

        }

    }

    &__items {
        @include display-flex();
        flex-direction: row;
        flex-wrap: wrap;
        flex-basis: 100%;
        padding: 1.5rem 1.5rem 1.5rem 0;

        @include below($lg) {
            padding-left: 1.5rem;
        }

        &-title {
            flex-basis: 100%;
            font-size: 1.3rem;

            @include below($lg) {
                font-size: 1.15rem;
            }

            &--top {
                font-family: $roboto-regular;
                margin: 0 .5rem 1rem;
            }

            &--bottom {
                margin: 0 0 1rem;
            }

        }

        &-text {
            flex-basis: 100%;
            border-style: solid;
            border-color: $gray;
            margin-left: .5rem;
            margin-right: .5rem;

            @include below($lg) {
                font-size: .85rem;
            }

            &--top {
                border-bottom-width: 2px;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
            }

            &--bottom {
                border-top-width: 2px;
                margin-top: 2rem;
                padding-top: 1rem;
            }

            & p {
                margin: 0;
            }

            & i {
                font-style: italic;
            }

        }

        &__item {
            @include display-flex();
            @include border-radius(5px);
            flex-direction: column;
            align-items: flex-start;
            width: calc(33.333% - 1rem);
            overflow: hidden;
            border: 1px solid rgba(144, 144, 144, .25);
            padding: 1rem;
            font-size: 0;
            margin: .5rem;

            @include below($xl) {
                width: calc(50% - 1rem);
            }

            @include below($lg) {
                margin-bottom: 1.5rem;
            }

            @include below($sm) {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
            }

            &-image {
                display: block;
                margin-bottom: .75rem;
                overflow: hidden;

                & > img {
                    @include transition-slow();
                    @include border-radius(2px);
                    width: 100%;

                    &:hover {
                        transform: scale(1.1);
                    }

                }

            }

            &__params {
                width: 100%;
                padding: 0;
                margin: .4rem 0 0;

                &-line {
                    @include display-flex();
                    flex-direction: row;
                    justify-content: space-between;
                    border-bottom: 1px dotted #909090;
                    margin-bottom: .25rem;

                    &-name, &-value {
                        position: relative;
                        bottom: -.35rem;
                        font-size: .85rem;
                        height: 100%;
                        background-color: $white;
                    }

                    &-name {
                        padding-right: .5rem;
                    }

                    &-value {
                        padding-left: .5rem;
                        color: #909090;
                    }

                }

            }

            &-price {
                @include transition;
                text-decoration: none;
                font-family: $roboto-regular;
                font-size: 1.2rem;
                color: $black;

                &:hover {
                    color: $black;
                    opacity: .85;
                }

            }

            &-title {
                @include transition();
                color: $dark-gray;
                text-decoration: none;
                font-size: 1rem;

                &:hover {
                    color: $black;
                    opacity: .85;
                }

            }

            &-push {
                flex-grow: 1;
            }

            &-available {
                position: relative;
                margin-top: 1rem;
                padding-left: 1rem;
                font-size: .9rem;
                color: $light-green;

                &:before {
                    content: '✔';
                    position: absolute;
                    left: 0;
                }

            }

            &-button {
                @include transition-slow();
                @include border-radius(2px);
                display: inline-block;
                font-size: .85rem;
                text-decoration: none;
                color: $white;
                background-color: $green;
                padding: .5rem 1.25rem;
                margin-top: .5rem;

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
                    color: $white;
                    opacity: .9;
                }

            }

        }

    }

}
