.main {

    &__splash {
        @include size(100%);
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $white;
        opacity: 0;
        pointer-events: none;

        &.show {
            opacity: 1;
        }

        &.transition {
            @include transition-manual(2s);
        }

        &.blink .main__splash-content:after {
            @include size(100%);
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom right, transparent 45%, #ffffff 50%, transparent 55%);
            background-size: 200% 200%;
            opacity: .35;
            animation: gradient .5s ease;
        }

        &.unblink .main__splash-content:after {
            opacity: 0;
        }

        &-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            text-align: center;

            &:after {
                @include transition-manual(1s);
                opacity: 0;
            }

            &-svg {
                @include size(150px);
                @include transition-manual(2s);
                fill: transparent;
                stroke-width: 40;
                opacity: 1;
                animation: dash 2s ease-in-out forwards;
                -webkit-filter: drop-shadow( 0 0 1rem rgba(0, 0, 0, .15));
                filter: drop-shadow( 0 0 1rem rgba(0, 0, 0, .15));

                @include below($md) {
                    @include size(80px);
                }

                &.hide {
                    opacity: 0;
                }

                &--g {
                    stroke: url(#svg-main-splash-gradient--g);
                    stroke-dasharray: 737;
                    stroke-dashoffset: 737;
                }

                &--e {
                    stroke: url(#svg-main-splash-gradient--e);
                    stroke-dasharray: 734;
                    stroke-dashoffset: 734;
                }

            }

            & > svg:first-child {
                margin-right: 15px;

                @include below($md) {
                    margin-right: 7px;
                }

            }

            &-title {
                @include transition-manual(2s);
                position: relative;
                z-index: 1;
                margin-top: 2rem;
                font-size: 0;
                pointer-events: none;
                overflow: hidden;
                white-space: nowrap;
                width: 0;
                background-color: #366163;
                background-image: linear-gradient(90deg, #43797b 40%, #92aa46 55%, #92aa46);
                background-size: 100%;
                background-repeat: repeat;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-shadow: 0 0 1rem rgba(0, 0, 0, .15);
                font-size: 2.1rem;
                font-family: $roboto-regular;
                letter-spacing: -.1rem;

                @include below($md) {
                    margin-top: 1.5rem;
                    font-size: 1.2rem;
                }

                &.show {
                    opacity: 1;
                    animation: animated-header-logo-title 1s forwards;

                    &.no-opacity {
                        opacity: 0;
                    }

                }

            }

        }

    }

    &__carousel {
        @include border-radius(.5rem);
        position: relative;
        margin-top: 1rem;
        box-shadow: 0 13px 20px rgba(153, 153, 153, 0.2);
        font-size: 0;
        overflow: hidden;
        height: 500px;

        @include below($md) {
            height: 220px;
            margin-top: 0;
        }

        &-wrapper {
            position: relative;

            & .slick-dots {
                position: absolute;
                width: 100%;
                top: calc(100% + 1rem);
                left: 0;
                padding-left: 0;
                text-align: center;

                @include below($md) {
                    top: 100%;
                }

                & li {
                    display: inline-block;
                    margin: 0 5px;

                    & button {
                        @include border-radius(50%);
                        @include transition();
                        @include size(10px, 12px);
                        display: block;
                        font-size: 0;
                        line-height: 0;
                        border: none;
                        background-color: $base;
                        opacity: .25;
                    }

                    &.slick-active button {
                        opacity: .75;
                    }

                }

            }

        }

        &-arrow {
            @include transition-manual(1s);
            @include size(40px, 68px);
            position: absolute;
            top: 50%;
            background-image: url('/images/base/icons/chevron-slim-right.svg');
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 100%;
            opacity: 0;
            display: block;

            @include below(1500px) { @include size(30px, 51px); }
            @include below(1400px) { @include size(40px, 68px); }
            @include below(1300px) { @include size(30px, 51px); }
            @include below(1200px) { @include size(40px, 68px); }
            @include below(1100px) { @include size(30px, 51px); }
            @include below(1050px) { @include size(20px, 34px); }
            @include below(992px) { @include size(40px, 68px); }
            @include below(850px) { @include size(30px, 51px); }
            @include below(800px) { @include size(20px, 34px); }
            @include below(767px) { @include size(40px, 68px); }
            @include below(700px) { @include size(20px, 34px); }
            @include below(600px) { display: none !important }

            &.slick-arrow {
                opacity: 1;
            }

            &.slick-disabled {
                opacity: 0;
            }

            &:hover {
                opacity: .75;
            }

            &--prev {
                right: calc(100% + 1rem);
                transform: translateY(-50%) rotate(180deg);

                @include below(1500px) { right: 100%; }
                @include below(1400px) { right: calc(100% + 1rem); }
                @include below(1300px) { right: 100%; }
                @include below(1200px) { right: calc(100% + 1rem); }
                @include below(1100px) { right: 100%; }
                @include below(1050px) { right: calc(100% - .5rem); }
                @include below(992px) { right: calc(100% + 1rem); }
                @include below(850px) { right: 100%; }
                @include below(800px) { right: calc(100% - .5rem); }
                @include below(767px) { right: calc(100% + 1rem); }
                @include below(700px) { right: calc(100% - .5rem); }

            }

            &--next {
                left: calc(100% + 1rem);
                transform: translateY(-50%);

                @include below(1500px) { left: 100%; }
                @include below(1400px) { left: calc(100% + 1rem); }
                @include below(1300px) { left: 100%; }
                @include below(1200px) { left: calc(100% + 1rem); }
                @include below(1100px) { left: 100%; }
                @include below(1050px) { left: calc(100% - .5rem); }
                @include below(992px) { left: calc(100% + 1rem); }
                @include below(850px) { left: 100%; }
                @include below(800px) { left: calc(100% - .5rem); }
                @include below(767px) { left: calc(100% + 1rem); }
                @include below(700px) { left: calc(100% - .5rem); }

            }

        }

        &-item {
            @include border-radius(.5rem);
            @include size(100%, 500px);
            position: relative;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;

            @include below($md) {
                height: 220px;
                background-size: cover;
                background-position: 65% 80% !important;
            }

            & .slide-element {
                position: absolute;
                display: block;
            }

            & .slide-button {
                @include border-radius(3px);
                text-decoration: none;
                color: $white;
                font-size: 1.25rem;
                background: linear-gradient(to bottom, #528a8e, #385f62);
                padding: 9px 35px 9px 17px;

                @include below($md) {
                    font-size: 1rem;
                    padding: 5px 25px 5px 12px;
                }

                &:after {
                    @include transition();
                    @include size(19px, 17px);
                    content: '';
                    position: absolute;
                    right: .35rem;
                    top: 50%;
                    background-image: url('/images/base/icons/chevron-slim-right--white.svg');
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-size: contain;
                    transform: translateY(-50%);

                    @include below($md) {
                        @include size(16px, 13px);
                        right: .15rem;
                    }
                }

            }

            &.slide1 {
                background-image:url('/images/temp/main-carousel/traysealer-ge3xl.webp');
                background-color: #f3ede9;

                @include below($md) {
                    background-position: 25% 100%;
                }

            }

            &.slide2.is-lazy-loaded {
                background-image:url('/images/temp/main-carousel/traysealer-ge3x_2.webp');
                background-color: #f3ede9;

                @include below($md) {
                    background-position: 40% 100% !important;
                }

            }

            &.slide3.is-lazy-loaded {
                background-image:url('/images/temp/main-carousel/knives.webp');
                background-color: #f3ede9;
            }

            & .slide1-title, & .slide2-title {
                font-size: 2.5rem;
                font-family: $roboto-medium;

                @include below($xl) {
                    font-size: 2rem;
                }

                @include below($md) {
                    font-size: 1.5rem;
                }

            }

            & .slide1-info, & .slide2-info {
                font-size: 2rem;

                @include below($xl) {
                    font-size: 1.5rem;
                }

                @include below($md) {
                    font-size: 1rem;
                }

                & em {
                    @include border-radius(3px);
                    @include padding-x(.75rem);
                    @include padding-y(.25rem);
                    white-space: nowrap;
                }

            }

            & .slide1 {

                &-title, &-info, &-button {
                    @include transition-manual(2s);
                    right: 3rem;
                    opacity: 1;
                    visibility: visible;

                    @include below($md) {
                        right: 2rem;
                    }

                    &.hide {
                        opacity: 0;
                        visibility: hidden;
                    }

                }

                &-title {
                    top: 5rem;

                    @include below($md) {
                        top: 1.5rem;
                    }

                }

                &-info {
                    width: 35rem;
                    top: 10rem;
                    text-align: right;

                    @include below($xl) {
                        width: 25rem;
                    }

                    @include below($md) {
                        top: 4rem;
                        width: 12rem;
                    }

                    & em {
                        background: linear-gradient(to bottom, #a2ba00, #7a8c00);
                        color: $white;
                    }

                }

                &-button {
                    top: 21rem;

                    @include below($md) {
                        top: 9.5rem;
                    }

                }

            }

            & .slide2 {

                &-title, &-info, &-button {
                    @include transition-manual(2s);
                    left: 3rem;

                    @include below($md) {
                        left: 2rem;
                    }

                }

                &-title {
                    top: 5rem;

                    @include below($md) {
                        top: 1rem;
                    }

                }

                &-info {
                    width: 40rem;
                    top: 10rem;
                    text-align: left;

                    @include below($xxl) {
                        width: 35rem;
                    }

                    @include below($xl) {
                        width: 25rem;
                    }

                    @include below($md) {
                        top: 3.5rem;
                        width: 14rem;
                    }

                    & em {
                        background: linear-gradient(to bottom, #ffbf42, #f39e3f);
                    }

                }

                &-button {
                    top: 21rem;

                    @include below($md) {
                        top: 10rem;
                        display: inline-block !important;
                        width: auto;
                    }

                }

            }

            & .slide3 {

                &-first, &-second, &-third {
                    @include border-radius(.25rem);
                    position: absolute;
                    text-transform: uppercase;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: $white;
                    padding: .25rem 1.5rem;
                    color: $base;
                    white-space: nowrap;
                }

                &-first {
                    top: 8.5rem;
                    font-size: 2rem;

                    @include below($xl) {
                        top: 9.75rem;
                        font-size: 1.5rem;
                    }

                    @include below($md) {
                        top: 2rem;
                        font-size: 1rem;
                    }

                }

                &-second {
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background: linear-gradient(to bottom, #a2ba00, #7a8c00);
                    color: $white;
                    font-size: 4rem;
                    padding: .25rem 2rem;
                    font-family: $roboto-regular;

                    @include below($xl) {
                        font-size: 3rem;
                    }

                    @include below($md) {
                        font-size: 1.5rem;
                        top: 5.75rem;
                    }

                }

                &-third {
                    bottom: 7.75rem;
                    font-size: 2.5rem;
                    font-family: $roboto-regular;

                    @include below($xl) {
                        font-size: 2rem;
                        bottom: 9rem;
                    }

                    @include below($md) {
                        font-size: 1.25rem;
                        bottom: 4rem;
                    }

                }

                &-button {
                    bottom: 0;
                    right: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 1rem;
                }

            }

        }

    }

    &__slider {
        @include display-flex();
        flex-direction: row;
        flex-wrap: nowrap;
        flex-grow: 1;
        justify-content: stretch;
        align-items: stretch;

        &-wrapper {
            position: relative;
            margin-bottom: 6rem;

            @include below($md) {
                margin-bottom: 4rem;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: -4rem;
                width: 100%;
                height: 1px;
                background-color: rgba(177, 177, 177, .25);

                @include below($md) {
                    bottom: -2.75rem;
                }

            }

            & .slick-dots {
                position: absolute;
                width: 100%;
                top: calc(100% + .75rem);
                left: 0;
                padding-left: 0;
                text-align: center;

                @include below($md) {
                    top: 100%;
                }

                & li {
                    display: inline-block;
                    margin: 0 5px;

                    & button {
                        @include border-radius(50%);
                        @include transition();
                        @include size(10px, 12px);
                        display: block;
                        font-size: 0;
                        line-height: 0;
                        border: none;
                        background-color: $base;
                        opacity: .25;
                    }

                    &.slick-active button {
                        opacity: .75;
                    }

                }

            }

        }

        & .slick-list {
            margin: 0 -.5rem;
            width: 100% !important;

            & .slick-slide {
                margin: .5rem 1rem;

                @include below($xxl) {
                    margin: .65rem;
                }

            }

        }

        &-arrow {
            @include transition();
            @include size(30px, 51px);
            position: absolute;
            top: 50%;
            background-image: url('/images/base/icons/chevron-slim-right.svg');
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 100%;
            opacity: 0;

            @include below(1500px) { @include size(30px, 51px); }
            @include below(1400px) { @include size(40px, 68px); }
            @include below(1300px) { @include size(30px, 51px); }
            @include below(1200px) { @include size(40px, 68px); }
            @include below(1100px) { @include size(30px, 51px); }
            @include below(1050px) { @include size(20px, 34px); }
            @include below(992px) { @include size(40px, 68px); }
            @include below(850px) { @include size(30px, 51px); }
            @include below(800px) { @include size(20px, 34px); }
            @include below(767px) { @include size(40px, 68px); }
            @include below(700px) { @include size(20px, 34px); }
            @include below(600px) { display: none !important }

            &.slick-arrow {
                opacity: 1;
            }

            &.slick-disabled {
                opacity: 0;
            }

            &:hover {
                opacity: .75;
            }

            &--prev {
                right: calc(100% + 1rem);
                transform: translateY(-50%) rotate(180deg);

                @include below(1500px) { right: 100%; }
                @include below(1400px) { right: calc(100% + 1rem); }
                @include below(1300px) { right: 100%; }
                @include below(1200px) { right: calc(100% + 1rem); }
                @include below(1100px) { right: 100%; }
                @include below(1050px) { right: calc(100% - .5rem); }
                @include below(992px) { right: calc(100% + 1rem); }
                @include below(850px) { right: 100%; }
                @include below(800px) { right: calc(100% - .5rem); }
                @include below(767px) { right: calc(100% + 1rem); }
                @include below(700px) { right: calc(100% - .5rem); }

            }

            &--next {
                left: calc(100% + 1rem);
                transform: translateY(-50%);

                @include below(1500px) { left: 100%; }
                @include below(1400px) { left: calc(100% + 1rem); }
                @include below(1300px) { left: 100%; }
                @include below(1200px) { left: calc(100% + 1rem); }
                @include below(1100px) { left: 100%; }
                @include below(1050px) { left: calc(100% - .5rem); }
                @include below(992px) { left: calc(100% + 1rem); }
                @include below(850px) { left: 100%; }
                @include below(800px) { left: calc(100% - .5rem); }
                @include below(767px) { left: calc(100% + 1rem); }
                @include below(700px) { left: calc(100% - .5rem); }

            }
        }

        &__item {
            @include border-radius(.25rem);
            @include margin-x(.5rem);
            @include padding-x(1rem);
            @include padding-y(1.5rem);
            box-shadow: 0 .35rem .25rem 0 rgba(177, 177, 177, .25);
            flex-basis: 33.333%;
            width: 33.333%;
            border: 1px solid rgba(177, 177, 177, .1);
            background-color: $white;
            text-align: center;

            &-image {
                display: block;
                max-width: 100%;

                & > img {
                    width: 100%;
                    max-width: 100%;
                }

            }

            &-price {
                @include transition;
                display: block;
                margin-top: .75rem;
                margin-bottom: .5rem;
                text-decoration: none;
                font-family: $roboto-regular;
                font-size: 1.2rem;
                color: $black;

                @include below($md) {
                    font-size: 1rem;
                }

                &:hover {
                    color: $black;
                    opacity: .85;
                }

            }

            &-title {
                @include transition();
                display: block;
                color: $dark-gray;
                text-decoration: none;
                font-size: 1rem;

                @include below($xl) {
                    font-size: .95rem;
                    line-height: 1.25;
                }

                @include below($md) {
                    font-size: .8rem;
                }

                &:hover {
                    color: $black;
                    opacity: .85;
                }

            }

            &-button {
                @include transition-slow();
                @include border-radius(2px);
                display: inline-block;
                font-size: .85rem;
                text-decoration: none;
                color: $white;
                background-color: $green;
                padding: .5rem 1.25rem;
                margin-top: 1rem;

                @include below($md) {
                    padding: .35rem 1rem;
                }

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
                    color: $white;
                    opacity: .9;
                }

            }

        }

    }

    &__tagline {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        padding: 2rem 15%;
        text-align: center;
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;
        transform: translateY(9rem);
        color: $white;
        box-shadow: 0 10px 10px rgba(0, 0, 0, .15);

        &:before {
            @include transition();
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 0;
            background: linear-gradient(to bottom, #528a8e, #385f62);
        }

        &.on-display:before {
            width: 100%;
        }

        @include below($xl) {
            padding: 1.5rem 10%;
            margin-top: .75rem;
            margin-bottom: 0;
        }

        @include below($lg) {
            transform: translateY(12rem);
            font-size: 1.25rem;
        }

        @include below($md) {
            @include padding-x(1rem);
            position: relative;
            transform: none;
            margin-top: 1.5rem;
        }

        &-content {
            position: relative;
            z-index: 1;
            font-size: 2rem;
            font-family: $roboto-thin;

            @include below($xl) {
                font-size: 1.5rem;
            }

            @include below($lg) {
                font-size: 1.25rem;
            }

            @include below($md) {
                font-size: 1rem;
                font-family: $roboto-light;
            }

        }

        & em {
            display: inline-block;
            border-bottom: 4px solid $light-green;
            line-height: 1.35;
            font-family: $roboto-light;

            @include below($lg) {
                border-width: 3px;
            }

            @include below($md) {
                border-width: 2px;
                font-family: $roboto-regular;
            }

        }

    }

    &__about {
        @include display-flex();
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-top: 13.5rem;
        margin-bottom: 2rem;
        position: relative;
        overflow: hidden;

        @include below($xl) {
            margin-top: 11rem;
        }

        @include below($md) {
            margin-top: 2rem;
        }

        &-image {
            position: absolute;
            right: 0 !important;
            width: 40rem !important;
            height: 100% !important;
            z-index: 1;
            background-image: url('/images/base/main-circles-image.webp');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 0 50% !important;

            @include below($xxl) {
                width: 35rem !important;
            }

            @include below($xl) {
                width: 28rem !important;
            }

            @include below($lg) {
                width: 20rem !important;
                background-size: 30rem;
            }

            @include below($md) {
                width: 100% !important;
                background-size: cover;
            }

            @include below($md) {
                width: 100% !important;
                background-size: 50rem;
                background-position: 50% 50% !important;
            }

        }

        &-content {
            @include border-radius(.5rem);
            position: relative;
            width: 40rem;
            z-index: 2;
            background-color: $white;
            margin: 2rem 0 2rem 2rem;
            padding: 1.5rem;
            box-shadow: 0 13px 20px rgba(153, 153, 153, 0.2);

            @include below($xxl) {
                width: 35rem;
            }

            @include below($xl) {
                width: 30rem;
            }

            @include below($lg) {
                width: 25rem;
                margin-left: 1rem;
                box-shadow: 0 13px 10px rgba(153, 153, 153, 0.1);
                padding-bottom: .5rem;
            }

            @include below($md) {
                margin: 5rem;
                box-shadow: none;
                width: auto;
            }

            @include below($md) {
                margin: 5rem 1.5rem;
                box-shadow: 0 13px 10px rgba(153, 153, 153, 0.1);
            }

            &-title {
                font-size: 2rem;
                text-transform: uppercase;
                display: inline-block;
                margin-bottom: 0;
                margin-bottom: .35rem;

                @include below($xl) {
                    font-size: 1.75rem;
                }

            }

            &-sub-title {
                display: block;
                text-transform: uppercase;
                font-size: 1.1rem;
                margin-bottom: 2rem;
                color: $dark-gray;

                @include below($xl) {
                    font-size: .85rem;
                }

                @include below($lg) {
                    margin-bottom: 1rem;
                }

            }

            &-info {
                margin-top: 1rem;
                padding-left: 0;
                font-size: 1.15rem;

                @include below($xl) {
                    font-size: 1rem;
                }

                @include below($lg) {
                    font-size: .95rem;
                }

            }

        }

    }

    &__catalog-button {
        @include transition();
        color: $light-green;
        text-decoration: none;
        border-bottom: 2px solid $light-green;
        font-size: 2rem;

        @include below($lg) {
            font-size: 1.5rem;
        }

        @include below($md) {
            font-size: 1.25rem;
        }

        @include below($sm) {
            font-size: 1.15rem;
        }

        &:hover {
            color: $light-green;
            opacity: .7;
        }

        &-wrapper {
            @include border-radius(5px);
            left: 0;
            right: 0;
            width: 100%;
            background-color: $gray;
            padding: 2rem 15%;
            text-align: center;
            margin: 0 .5rem 2.5rem;
            position: relative;

            &:before, &:after {
                content: '';
                position: absolute;
                width: 10rem;
                height: .35rem;
                opacity: .15;
                left: 0;
                right: 0;
                background-color: $dark-gray;
                margin: 0 auto;
            }

            &:before {
                bottom: 100%;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
            }

            &:after {
                top: 100%;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }

        }

    }

    &__features {
        @include display-flex();
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 4.5rem;
        margin-bottom: 2rem;
        width: 100%;

        @include below($md) {
            margin-top: 3rem;
        }

        &-item {
            @include transition-slow();
            @include border-radius(5px);
            @include display-flex();
            width: 26rem;
            flex-direction: row;
            border: 1px solid $gray;
            opacity: 0;
            visibility: hidden;
            background-color: $white;
            padding: 1.5rem;
            font-size: 0;
            margin-bottom: 1rem;

            @include below($xxl) {
                width: 22rem;
            }

            @include below($xl) {
                width: 19rem;
            }

            @include below($lg) {
                width: 100%;
                padding: .75rem;
                margin-bottom: .5rem;
            }

            &.show {
                opacity: 1;
                visibility: visible;
            }

            &.wide {
                width: 53rem;

                @include below($xxl) {
                    width: 45rem;
                }

                @include below($xl) {
                    width: 38.5rem;
                }

                @include below($lg) {
                    width: 100%;
                }

            }

            &-icon {
                font-size: 1rem;
                flex-shrink: 0;
                margin-right: 1rem;
                padding-top: .25rem;
                opacity: .5;

                @include below($lg) {
                    padding-top: 0;
                }

                & img {
                    @include size(32px);
                    max-width: 32px;
                    max-height: 32px;
                    overflow: hidden;

                    @include below($lg) {
                        @include size(24px);
                        max-width: 24px;
                        max-height: 24px;
                    }

                }

            }

            &-info {
                color: $base;
                flex-grow: 1;
                font-size: 1.15rem;

                @include below($xl) {
                    font-size: .95rem;
                }

            }

            & a {
                @include transition();
                color: inherit;
                font-weight: bold;

                &:hover {
                    color: #909090;
                }

            }

        }

    }

    &__special {
        @include border-radius(5px);
        @include margin-x(auto);
        width: 33rem;
        color: $base;
        margin-bottom: 3rem;
        font-size: 1.5rem;
        text-align: center;

        @include below($xl) {
            width: 27rem;
            font-size: 1.25rem;
        }

        @include below($lg) {
            width: auto;
            font-size: 1.25rem;
        }

        @include below($md) {
            font-size: 1rem;
            font-family: $roboto-regular;
            margin-bottom: 2rem;
        }

        &-button {
            @include transition();
            display: inline-block;
            color: $light-green;
            text-decoration: none;
            font-family: $roboto-light;
            margin-left: .75rem;
            border-bottom: 2px solid $light-green;

            &:hover {
                color: $light-green;
                opacity: .75;
            }

        }

    }

}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes gradient {
    0% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}
