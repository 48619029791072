.link {
    @include transition();
    color: $light-green;

    &:hover {
        color: $base;
    }

    &--dashed {
        display: inline-block;
        line-height: 1.15;
        text-decoration: none;
        border-bottom: 1px dashed $light-green;

        &:hover {
            border-color: $base;
        }

    }

}
