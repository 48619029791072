.breadcrumbs {
    position: relative;
    margin-top: -.5rem;
    top: -.25rem;
    width: 100%;
    font-size: 0;
    padding-left: .5rem;

    @include below($lg) {
        top: 0;
    }

    &__item {
        display: inline-block;
        margin: 0;

        &:after {
            content: '●';
            position: relative;
            top: -.1rem;
            display: inline-block;
            margin: 0 .35rem;
            font-size: .5rem;
            color: $dark-gray;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &-link {
            @include transition();
            display: inline-block;
            font-size: 1rem;
            color: $light-green;
            text-decoration: none;

            @include below($lg) {
                font-size: .85rem;
            }

            &:hover {
                color: $black;
            }

            &.active {
                color: $dark-gray;
                cursor: text;
            }

        }

    }

}
