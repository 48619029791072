.header {
    @include padding-y(.75rem);
    flex-shrink: 0;
    margin-bottom: 1.5rem;
    background-color: $white;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .035);

    @include below($lg) {
        @include padding-y(.25rem);
    }

    &.fixed {
        @include padding-y(.25rem);
        position: fixed;
        top: 0;
        z-index: 100;
        width: 100%;
        box-shadow: 0 10px 10px rgba(0, 0, 0, .05);

        @include below($lg) {
            @include padding-y(0);
            box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
        }

        & .header-logo-svg {
            @include size(25px !important);

            @include below($lg) {
                @include size(22px !important);
            }

        }

        & .header-logo-title {
            top: 0;
            font-size: 1.7rem;

            @include below($xl) {
                margin-left: .25rem;
                font-size: 1.6rem;
            }

            @include below($lg) {
                top: 1px;
                font-size: 1.4rem;
            }

        }

        & .header__nav-item-link {
            padding-bottom: 1px;
            font-size: 1rem;

            &--dropdown {
                padding-right: 1rem;

                &:after {
                    top: calc(50% + 3px);
                }
            }

        }

    }

    &-logo {
        @include display-flex();
        flex-direction: row;
        align-items: center;
        flex-grow: 1;

        &-svg {
            @include transition();
            @include size(35px);
            fill: transparent;
            stroke-width: 40;
            animation: dashHeader 3s ease-in-out forwards infinite;
            animation-direction: alternate;

            @include below($xxl) {
                @include size(30px);
            }

            @include below($xl) {
                @include size(25px);
            }

            @include below($lg) {
                @include size(35px);
            }

            @include below($md) {
                @include size(22px);
            }

            &--g {
                stroke: url(#svg-header-gradient--g);
                stroke-dasharray: 737;
                stroke-dashoffset: 737;
            }

            &--e {
                stroke: url(#svg-header-gradient--e);
                stroke-dasharray: 734;
                stroke-dashoffset: 734;
            }

        }

        &-title {
            @include transition();
            position: relative;
            display: inline-block;
            top: 2px;
            margin-left: .5rem;
            text-transform: uppercase;
            font-family: $roboto-regular;
            font-size: 2rem;
            color: $light-green;
            vertical-align: middle;
            line-height: 1;
            letter-spacing: -.1rem;
            overflow: hidden;
            white-space: nowrap;
            width: 0;
            height: 100%;
            animation: animated-header-logo-title 1s forwards;

            @include below($xxl) {
                top: 0;
                font-size: 1.75rem;
            }

            @include below($xl) {
                font-size: 1.5rem;
            }

            @include below($lg) {
                font-size: 2rem;
            }

            @include below($md) {
                font-size: 1.4rem;
                margin-left: .25rem;
                top: 1px;
            }

            & > em {
                display: inline-block;
                color: $green;
                margin-right: .5rem;

                @include below($md) {
                    margin-right: .25rem;
                }

            }

        }

    }

    &__toggler {

        &-btn {
            @include transition();
            background-color: $green;
            padding: 5px 8px !important;

            &:focus {
                box-shadow: none;
                background-color: $light-green;
            }

            &-icon {
                width: 20px;
                height: 2px;
                background-color: $white;
                margin: 3px 0;
                display: block;
                position: relative;
            }

        }

    }

    &__nav {
        position: relative;
        flex-shrink: 0;
        z-index: 5;
        margin-left: auto;

        @include below($lg) {
            @include border-radius(5px);
            background-color: $white;
            margin: .75rem 0;
            padding: .5rem 0;
        }

        &-item {
            position: relative;
            margin-left: 35px;

            &:first-child {
                margin-left: 0;
            }

            @include below($lg) {
                margin-left: 1rem !important;
                padding: .5rem;
            }

            @include between($lg, $xxl) {
                margin-left: 20px;
            }

            &:hover {

                & .header__nav-item-link {
                    color: $light-green;

                    &.active {
                        color: $light-green;
                    }

                }

                & .header__nav-item-link:after {
                    transform: translateY(-50%) rotate(-90deg);
                }

                & .header__nav-item__sub {
                    opacity: 1;
                    visibility: visible;
                }

            }

            &-link {
                position: relative;
                padding: 4px 0;
                font-size: 1.15rem;
                text-decoration: none;
                color: $base;
                font-family: $roboto-regular;
                border-bottom: 2px solid $light-green;

                @include between($lg, $xl) {
                    font-size: 1rem;
                }

                @include below($lg) {
                    border-bottom: none;
                }

                &:hover {
                    color: $light-green;
                }

                &.active {
                    font-weight: bold;
                    color: $base;
                    border-color: transparent;
                }

                &--dropdown {
                    padding-right: 1.15rem;

                    &.hovered {
                        border-color: transparent;
                    }

                    &:after {
                        @include transition();
                        content: '';
                        position: absolute;
                        width: 8px;
                        height: 14px;
                        background-image: url('/images/base/icons/chevron-slim-right.svg');
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-size: cover;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%) rotate(90deg);

                        @include below($lg) {
                            display: none;
                        }

                    }

                }

            }

            &__sub {
                @include border-radius(3px);
                @include transition();
                background-color: $white;
                width: 220px;
                text-align: left;
                position: absolute;
                z-index: 999;
                top: calc(100% + .5rem);
                opacity: 0;
                visibility: hidden;
                padding: 10px 15px;
                left: -15px;
                margin: 0;
                box-shadow: 0 15px 20px rgba(153, 153, 153, 0.35);
                font-size: 0;

                @include below($lg) {
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                    box-shadow: none;
                    width: auto;
                    left: 0;
                    padding: 10px 15px 0;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: -.5rem;
                    left: 0;
                    width: 100%;
                    height: .5rem;

                    @include below($lg) {
                        display: none;
                    }

                }

                &-item {
                    display: block;
                    border-bottom: 1px solid #eee;

                    &:last-child {
                        border-bottom: none;
                    }

                    @include below($lg) {
                        border-color: transparent;
                    }

                    &-link {
                        display: block;
                        color: $base;
                        text-decoration: none;
                        font-size: 1rem;
                        font-family: $roboto-regular;
                        line-height: 1.5rem;
                        padding: .6rem .5rem !important;
                        padding-top: .35rem !important;

                        &:hover {
                            @include border-radius(3px);
                            color: $light-green;

                            @include below($lg) {
                                background-color: transparent !important;
                                color: $light-green !important;
                            }

                        }

                        &.dark-gray {
                            color: $dark-gray;
                        }

                        &.padding-top {
                            padding-top: 1rem !important;
                        }

                        &.padding-bottom {
                            padding-bottom: 1rem !important;
                        }

                        &.active {
                            font-weight: bold;
                            color: $light-green;
                            pointer-events: none;
                        }

                        & > em {
                            display: block;
                            font-size: .75rem;
                            color: $base;
                            line-height: 1.15;
                            padding-left: .05rem;
                            opacity: .5;
                        }

                    }

                }

            }

        }

    }

}

@keyframes dashHeader {
    0% {
        stroke-dashoffset: 735;
    }
    20% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes animated-header-logo-title {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
