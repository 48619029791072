.contacts {
    @include display-flex();
    flex-direction: row;
    flex-basis: 100%;
    background-color: $white;

    @include below($xl) {
        flex-direction: column;
    }

    &__common {
        @include display-flex();
        flex-direction: column;
        flex-grow: 1;
        margin-right: 1.5rem;
        padding: 1.5rem 2rem;
        font-size: 1.1rem;

        @include below($xl) {
            margin-right: 0;
            flex-direction: row;
        }

        @include below($lg) {
            font-size: .95rem;
        }

        @include below($md) {
            flex-direction: column;
        }

        &-item:first-child {
            @include below($xl) {
                margin-right: 2rem;
            }
            @include below($lg) {
                margin-right: 1rem;
            }
        }

        &-title {
            font-size: 1.35rem;

            @include below($lg) {
                font-size: 1.15rem;
            }

        }

        &-info {
            border-left: 2px solid $light-green;
            padding-left: 1rem;
            margin-bottom: 3rem;

            &-youtube, &-vkontakte {
                & strong img {
                    @include size(18px);
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: .25rem;
                }
                & a img {
                    @include size(12px);
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            &-navigator-link {
                @include transition();
                position: relative;
                display: inline-block;
                border-bottom: 2px solid $light-green;
                line-height: 1;
                color: $base;
                text-decoration: none;
                margin-left: 1.25rem;
                margin-top: .5rem;

                &:hover {
                    color: $light-green;
                }

                &:before {
                    @include size(14.5px, 14.5px);
                    content: '';
                    position: absolute;
                    left: -1.25rem;
                    background: transparent url('/images/base/icons/wheel.svg') no-repeat 50% 50%;
                    background-size: 100%;
                }

                @include above($lg) {
                    display: none;
                }

            }

        }

    }

    &__map {
        flex-shrink: 0 ;
        width: 39rem;
        border: 1px solid $gray;

        @include below($xxl) {
            width: 32rem;
        }

        @include below($xl) {
            width: 100%;
            height: 20rem;
        }

    }

}
