.blocker {
	z-index: 100;
	background-color: rgba(0, 0, 0, .4);
	color: $base;
}

.modal {
	@include padding-y(4rem);
	@include padding-x(3.2rem);
	@include border-radius(0);
	max-width: 56rem;
	box-shadow: none;
	width: 100%;
	position: relative;
	height: auto;

	@include below($xxl) {
		@include padding-y(3.8rem);
		@include padding-x(3rem);
	}

	@include below($xl) {
		@include padding-y(3.6rem);
		@include padding-x(2.8rem);
	}

	@include below($lg) {
		@include padding-y(3.4rem);
		@include padding-x(2.6rem);
	}

	@include below($md) {
		@include padding-y(3.4rem);
		@include padding-x(2.4rem);
	}

	&--m {
		max-width: 40rem;
	}

	&--s {
		max-width: 30rem;
	}

	&--xs {
		max-width: 24rem;
	}

	a.close-modal {
		@include transition();
		@include size(1.5rem);
		position: absolute;
		top: 1rem;
		right: 1rem;
		background: url('/images/base/icons/close.svg') no-repeat 50% 50%;
		background-size: auto auto;
		opacity: .5;

		&:hover {
			opacity: 1;
		}

	}

	&__title {
		font-size: 1.3rem;
		line-height: 1.1;
		margin-bottom: .7rem;

		@include below($xl) {
			font-size: 1.25rem;
			line-height: 1.1;
		}

		@include below($md) {
			font-size: 1.2rem;
			line-height: 1.15;
		}

	}

	&__subtitle {
		font-size: .95rem;
		line-height: 1.3;

		@include below($xxl) {
			line-height: 1.15;
		}

	}

	&__head {
		font-size: 1rem;
		line-height: 1.75;
		margin-bottom: 1.5rem;
	}

	&__body {
		line-height: 1.4;

		& > p {
			@include margin-y(1rem);
		}

	}

	&__row {
		display: flex;
		flex-wrap: wrap;
	}

	&__col {
		width: 50%;
	}

	.popup__inner {
		max-height: 20rem;
	}

}
