@import 'node_modules/reset-css/sass/reset';
@import 'node_modules/jquery-modal/jquery.modal';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/slick-carousel/slick/slick.scss';

@import './helpers/variables';
@import './helpers/mixins';
@import './helpers/typography';


* {
    min-width: 0;
    min-height: 0;
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html, body {
    @include display-flex();
    flex-direction: column;
	font-family: $roboto-light;
    color: $base;
    font-size: 1rem;
    background-color: #f6f9fc;
    width: 100%;
    height: 100% !important;
}

body {
    opacity: 0;
    animation: body-show 1s forwards;
}

@keyframes body-show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@import '../_modules/preloader/preloader';
@import '../_modules/form/form';
@import '../_modules/button/button';
@import '../_modules/modal/modal';
@import '../_modules/link/link';
@import '../_modules/content/content';
@import '../_modules/gallery/gallery';

@import '../_modules/toolbar/toolbar';
@import '../_modules/header/header';
@import '../_modules/content-header/content-header';
@import '../_modules/breadcrumbs/breadcrumbs';
@import '../_modules/main/main';
@import '../_modules/catalog/catalog';
@import '../_modules/catalog/product';
@import '../_modules/contacts/contacts';
@import '../_modules/footer/footer';
