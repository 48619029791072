.toolbar {
    @include padding-y(.55rem);
    background-color: $base;
    flex-shrink: 0;

    &-wrapper {
        @include display-flex();
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $white;
        font-size: .85rem;

        @include below($sm) {
            justify-content: space-evenly;
            font-size: .7rem;
        }

    }

    &-item {

        &--address {
            @include below($xl) {
                display: none;
            }
        }

        &--phone &-link {
            margin-left: 1.75rem;

            @include below($md) {
                display: inline-block;
                border-bottom: 1px dashed $white;
            }

            @include below($sm) {
                margin-left: 1.5rem;
            }

        }

        &--email {
            @include below($md) {
                border-bottom: 1px dashed $white;
            }
        }

        &--callback {
            display: inline-block;
            border-bottom: 1px dashed $white;
            line-height: 1.15;

            @include below($sm) {
                display: none;
            }
        }

        &-link {
            text-decoration: none;
            color: $white;

            &:hover {
                color: $white;
            }

        }

        & em {
            opacity: .55;

            &:not(.not-hide) {
                @include below($lg) {
                    display: none;
                }
            }

            @include below($md) {
                display: none;
            }

        }

        &-icon {
            @include size(1.5rem);
            position: absolute;
            top: .45rem;
            display: inline-block;
            background-image: url('/images/base/icons/whatsapp.svg');
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: cover;

            @include below($sm) {
                @include size(1.25rem);
                top: .5rem;
            }
        }

    }

}
