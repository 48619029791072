.content {
    background-color: $white;
    padding: 2rem 2.5rem;
    border-top: 1px solid #eee;
    min-height: 100%;
    flex-grow: 1;
    font-size: 1.1rem;

    @include below($xxl) {
        font-size: 1rem;
    }

    @include below($md) {
        font-size: .9rem;
    }

    @include below($sm) {
        padding: 1rem;
    }

    &--height {
        @include display-flex();
        flex-wrap: wrap;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 100%;
        min-height: 100%;
        padding-bottom: 2rem;
    }

    & ol {
        list-style-type: decimal;
        margin-bottom: 1.75rem;
    }

    & ul {
        list-style-type: disc;
        margin-bottom: 1.75rem;
    }

    & ol li, & ul li {
        margin-bottom: 1rem;
    }

    h4 {
        font-family: $roboto-regular;
        margin-bottom: 1rem;
    }

}
