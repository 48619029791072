$base: rgba(0, 0, 0, .87);
$black: rgba(0, 0, 0, 1);
$white: rgba(255, 255, 255, 1);
$gray: rgba(243, 243, 243, 1);
$dark-gray: rgba(144, 144, 144, 1);
$red: #dc3545;
$orange: #ffa642;
$green: #447377;
$light-green: #91a700;

$roboto-thin: 'Roboto Thin';
$roboto-light: 'Roboto Light';
$roboto-regular: 'Roboto Regular';
$roboto-medium: 'Roboto Medium';

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
