.content-header {
    @include display-flex();
    flex-direction: row;
    flex-shrink: 0;
    font-size: 0;
    margin-bottom: 2rem;
    height: 4.3rem;

    @include below($lg) {
        display: block;
        margin-bottom: 1.5rem;
        height: auto;
    }

    &__item {
        @include border-radius(5px);
        padding: 15px 30px;
        border: 1px solid $gray;
        height: 4.3rem;

        @include below($lg) {
            display: inline-block;
            padding: 7px 15px;
            height: auto;
        }

        &--title {
            font-size: 1.5rem;
            font-family: $roboto-regular;
            background: linear-gradient(to bottom, #a2ba00, #7a8c00);
            color: $white;
            flex-shrink: 0;
            margin-right: 2rem;
            line-height: 1.5;


            @include below($lg) {
                margin-right: 0;
                font-size: 1.15rem;
            }

        }

        &--additional {
            @include display-flex();
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            font-size: 1rem;
            background-color: $white;
            flex-grow: 1;
            overflow: hidden;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;

            @include below($lg) {
                display: none;
            }

        }

        &__feature {
            @include transition-manual(1s);
            @include display-flex();
            align-items: flex-start;
            flex-direction: row;
            flex-shrink: 0;
            align-items: center;
            width: calc(19rem - 1rem);
            font-size: 0;
            margin: 1.1rem 1rem 3rem 0;
            opacity: 0;
            visibility: hidden;

            @include below($xl) {
                width: 18rem;
                margin-right: 0;
            }

            &.show {
                opacity: 1;
                visibility: visible;
            }

            &-item {
                display: inline-block;
                font-size: .83rem;
                line-height: 1.2;

                &--icon {
                    flex-shrink: 0;
                    margin: .1rem .5rem 0 0;
                    opacity: .5;

                    & img {
                        @include size(25px);
                        max-width: 25px;
                        max-height: 25px;
                        overflow: hidden;
                    }

                }

                &--info {
                    flex-grow: 1;
                    align-items: center;
                }

                & a {
                    @include transition();
                    color: inherit;
                    font-weight: bold;
                    text-decoration: none;

                    &:hover {
                        color: #909090;
                    }

                }

            }

        }

    }

}
