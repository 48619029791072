.footer {
    margin-top: auto;
    border-top: 1px solid #eee;
    flex-shrink: 0;
    padding-top: 1.5rem;
    padding-bottom: 3rem;

    @include below($md) {
        text-align: center;
    }

    &-youtube-icon, &-vkontakte-icon {
        @include transition();
        display: inline-block;
        position: relative;
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: 100%;
        position: relative;
        top: -5px;
        vertical-align: middle;

        @include below($md) {
            @include size(36px);
            top: 0;
        }

        &:after {
            @include transition();
            @include size(100%);
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-repeat: no-repeat;
            background-position: 0 50%;
            background-size: 100%;
            opacity: 0;
            visibility: hidden;
        }

        &:hover {
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }

        & img {
            @include size(32px);
        }

    }

    &-youtube-icon {
        @include size(38px);
        background-image: url('/images/base/icons/youtube--dark-gray.svg');

        &:after {
            background-image: url('/images/base/icons/youtube--red.svg');
        }

    }

    &-vkontakte-icon {
        @include size(26px);
        background-image: url('/images/base/icons/vkontakte--dark-gray.svg');

        &:after {
            background-image: url('/images/base/icons/vkontakte--blue.svg');
        }

    }

    &__links {
        text-align: right;
        margin-bottom: 0;
        padding-left: 0;

        @include below($md) {
            text-align: center !important;
            margin-top: 1rem;
        }

        &-item {
            display: inline-block;
            margin-left: 1rem;

            &:first-child {
                margin-left: 0;
            }

            & .link {
                color: $dark-gray;
                font-size: .85rem;

                @include below($md) {
                    font-size: .75rem;
                }

                &:hover {
                    color: $base;
                }

            }

        }

    }

}
