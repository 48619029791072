.product {
    @include display-flex();
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: flex-start;

    @include below($lg) {
        flex-direction: column;
    }

    &__carousel {
        @include border-radius(.5rem);
        box-shadow: 0 13px 20px rgba(153, 153, 153, 0.2);
        font-size: 0;
        overflow: hidden;

        &-wrapper {
            @include border-radius(.5rem);
            position: relative;
            margin-right: 2rem;
            margin-bottom: 2rem;
            font-size: 0;
            max-width: calc(100% - 24rem);

            @include below($lg) {
                margin-right: 0;
                margin-bottom: 3rem;
                max-width: 100%;
            }

            &:hover {
                & .slick-arrow {
                    background-color: rgba(0, 0, 0, .25);

                    &:after {
                        opacity: 1;
                    }

                }
            }

            & .slick-dots {
                position: absolute;
                width: 100%;
                top: calc(100% + 1rem);
                left: 0;
                padding-left: 0;
                text-align: center;

                & li {
                    display: inline-block;
                    margin: 0 7px;

                    @include below($xl) {
                        margin: 0 5px;
                    }

                    & button {
                        @include border-radius(50%);
                        @include transition();
                        @include size(16px);
                        display: block;
                        font-size: 0;
                        line-height: 0;
                        border: none;
                        background-color: $base;
                        opacity: .25;

                        @include below($xl) {
                            @include size(13px);
                        }

                    }

                    &.slick-active button {
                        opacity: .75;
                    }

                }

            }

        }

        & img {
            @include size(100%, auto);
            display: block;
        }

        &-arrow {
            @include transition-slow();
            @include size(5rem, 100%);
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0);
            z-index: 1;

            @include below($xxl) {
                width: 4rem;
            }

            @include below($xl) {
                width: 3rem;
            }

            @include below($sm) {
                display: none !important;
            }

            &.slick-arrow {
                background-color: rgba(0, 0, 0, 0);
            }

            &.slick-disabled {
                opacity: 0;
            }

            &:hover {
                background-color: rgba(0, 0, 0, .35) !important;
            }

            &--prev {
                left: 0;
                border-top-left-radius: .5rem;
                border-bottom-left-radius: .5rem;

                &:after {
                    transform: translateX(-50%) translateY(-50%) rotate(180deg) !important;
                }

            }

            &--next {
                right: 0;
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;

                &:after {
                    transform: translateX(-50%) translateY(-50%);
                }

            }

            &:after {
                @include transition();
                @include size(30px, 51px);
                content: '';
                position: absolute;
                top: 50%;
                background-image: url('/images/base/icons/chevron-slim-right--white.svg');
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: 100%;
                margin-left: 50%;
                opacity: 0;

                @include below($xl) {
                    @include size(20px, 34px);
                }

            }

        }

    }

    &__info {
        @include border-radius(.5rem);
        flex-shrink: 0;
        flex-basis: 22rem;
        color: $base;
        background-color: $white;
        padding: 1rem;

        @include below($lg) {
            flex-shrink: auto;
            flex-basis: auto;
            width: 100%;
        }

        &-h2 {
            font-size: 2rem;
            padding-bottom: .75rem;
            border-bottom: 2px solid #eee;
            margin-bottom: 1rem;

            @include below($lg) {
                font-size: 1.5rem;
            }

        }

        &-schema {
            display: none;
        }

        &-line {
            @include display-flex();
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: .5rem;
            border-bottom: 1px dotted $dark-gray;

            &.no-border {
                border-color: transparent;
            }

            &-name, &-value {
                position: relative;
                background-color: $white;
                bottom: -.35rem;
                z-index: 1;
            }

            &-name {
                padding-right: .35rem;
                font-family: $roboto-regular;
            }

            &-value {
                padding-left: .35rem;
            }

        }

        &-marketing {
            @include display-flex();
            justify-content: space-evenly;
            flex-direction: row;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;

            & > img {
                @include size(50px);
            }

        }

        &__footer {
            @include display-flex();
            flex-direction: row;
            align-items: center;

            &-button-request {
                @include transition();
                @include border-radius(3px);
                flex-shrink: 0;
                background: linear-gradient(to bottom, #528a8e, #385f62);
                color: $white;
                padding: .75rem 1.5rem;
                text-decoration: none;

                @include below($sm) {
                    display: block;
                    text-align: center;
                    padding: .75rem 1.25rem;
                }

                &:hover {
                    color: $white;
                    opacity:.85;
                }

            }

            &-link-download {

                &-wrapper {
                    flex-basis: 100%;
                    text-align: center;
                    padding-left: .5rem;

                    @include below($lg) {
                        text-align: right;
                    }

                }

                @include transition();
                position: relative;
                white-space: nowrap;
                flex-grow: 1;
                font-size: .8rem;
                color: $base;
                text-decoration: none;
                border-bottom: 2px solid $light-green;

                @include below($lg) {
                    margin-right: 1rem;
                }

                &:hover {
                    color: $light-green;
                }

                &:after {
                    @include size(18px);
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: calc(100% + .25rem);
                    transform: translateY(-50%);
                    background-image: url('/images/base/icons/pdf.svg');
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-size: contain;
                }

            }

        }

    }

    &__content {
        @include border-radius(.5rem);
        margin-top: 2rem;
        flex-basis: 100%;
        padding: 1.5rem 2rem;
        background-color: $white;

        @include below($lg) {
            flex-basis: auto;
            margin-top: 2rem;
            width: 100%;
        }

        @include below($sm) {
            padding: .75rem 1rem;
        }

        &-title {
            display: block;
            margin-bottom: 1rem;
            font-size: 1.75rem;
        }

        & ol {
            list-style-type: decimal;
            margin-bottom: 1.75rem;
        }

        & ul {
            list-style-type: disc;
            margin-bottom: 1.75rem;
        }

        & ol li, & ul li {
            margin-bottom: 1rem;
        }

    }

    & .main__special {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

}
