@mixin below($width, $orientation: 'width') {
    @media all and (max-#{$orientation}: $width) {
        @content;
    }
}

@mixin above($width, $orientation: 'width') {
    @media all and (min-#{$orientation}: $width) {
        @content;
    }
}

@mixin between($minWidth, $maxWidth, $orientation: 'width') {
    @media all and (min-#{$orientation}: $minWidth) and (max-#{$orientation}: $maxWidth) {
        @content;
    }
}

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin margin-x($size){
    margin-left: $size;
    margin-right: $size;
}

@mixin padding-x($size){
    padding-left: $size;
    padding-right: $size;
}
@mixin margin-y($size){
    margin-top: $size;
    margin-bottom: $size;
}

@mixin padding-y($size){
    padding-top: $size;
    padding-bottom: $size;
}

@mixin transition() {
    transition: all .25s ease;
}

@mixin transition-slow() {
    transition: all .5s ease;
}

@mixin transition-manual($time) {
    transition: all $time ease;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin display-flex() {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
}
